<template>
  <full-screen-loading />
</template>
<script>
import FullScreenLoading from '@/components/common/FullScreenLoading.vue'

export default {
  components: { FullScreenLoading },
  async mounted() {
    await this.$auth.handleRedirectCallback()
    await this.$router.push('/')
  },
}
</script>
